<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <h3 class="fw-bold text-success mb-4">
              Book treatments during your stay
            </h3>
            <treatment-form
              :mode="'create'"
              :room-bookings="roomBookings"
            ></treatment-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreatmentForm from "../../components/treatments/Form";

export default {
  components: {
    TreatmentForm
  },
  data() {
    return {
      roomBookings: []
    };
  },
  methods: {
    fetchRoomBookings() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/c/room-bookings")
        .then(({ data }) => {
          if (!Array.isArray(data)) {
            data = Object.values(data);
          }

          this.roomBookings = data.filter(
            rb => moment(rb.arrival_date) > moment()
          );
        });
    }
  },
  mounted() {
    this.fetchRoomBookings();
  }
};
</script>

<style></style>
